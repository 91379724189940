body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

selector::-webkit-scrollbar {
  width: 0;
}

.displayAnimate {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.a .nut-drag {
  z-index: 999 !important;
}

.fashionTab .nut-tabpane {
  padding: 0 !important;
}

.nut-menu .nut-menu__bar {
  line-height: 35px !important;
  height: 35px !important;
  box-shadow: none !important;
  background: none !important;
}

.overlay-fade-enter-active.nut-menu__overlay {
  opacity: 0;
}

.nut-menu-item__content {
  padding: 0 !important;
}

.nut-menu-item__content .nut-menu-item__option {
  justify-content: center;
}

.nut-textarea {
  padding: 0px 25px !important;
}